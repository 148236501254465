* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "montserratregular";
  src: url("../src/Fonts/montserrat-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/montserrat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "open_sansitalic";
  src: url("../src/Fonts/opensans-italic-variablefont_wdthwght-webfont.woff2")
      format("woff2"),
    url("../src/Fonts/opensans-italic-variablefont_wdthwght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "paytone_oneregular";
  src: url("../src/Fonts/paytoneone-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/paytoneone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "pt_serifbold";
  src: url("../src/Fonts/ptserif-bold-webfont.woff2") format("woff2"),
    url("../src/Fonts/ptserif-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "dm_sansregular";
  src: url("../src/Fonts/dmsans-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/dmsans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "racing_sans_oneregular";
  src: url("../src/Fonts/racingsansone-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/racingsansone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lobsterregular";
  src: url("../src/Fonts/lobster-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/lobster-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* spinner */

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 50vw;
  margin-top: 45vh;
  margin-bottom: 30vh;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #00a8cb;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: unset !important;
  margin: 24px 0;
}

/* ///loding2 */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  margin-top: 30vh;
  margin-bottom: 10vh;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #00a8cb;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* //antd button  */

.ant-btn-primary {
  border-color: #00a8cb !important;
  background: #00a8cb !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  background: #f5f5f5 !important;
  padding-left: 37px !important;
}

/* ///image zoom  */
.transform-component-module_content__2jYgh img {
  pointer-events: none;
  width: 280px !important;
}
